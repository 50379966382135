/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Button, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1so983 --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pt--25 flex--center" anim={null} style={{"maxWidth":1350}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--86 w--600 lh--1" style={{"maxWidth":700}} content={"<span style='color: white;'>Oftalomologická ambulance</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--26" content={"<span style='color: white;'>Soukromá ambulance v Brně</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22 mt--25" content={"Objednat k vyšetření"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"informace"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--2 pb--10 pt--10 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1350}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":596}} content={"Profesionální péče o vaše zdraví"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

              <Subtitle className="subtitle-box lh--16" content={"+420 797 811 2X0<br>info@vase-stranky.com"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":400}}>
              
              <Text className="text-box w--600" content={"Pondělí"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00, 13:00 — 15:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" style={{"maxWidth":400}} content={"Uterý"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" content={"Středa"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00, 13:00 — 15:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" content={"Čtvrtek"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00"}>
              </Subtitle>

              <Text className="text-box w--600 mt--10" content={"Pátek"}>
              </Text>

              <Subtitle className="subtitle-box mt--02" content={"7:00 — 11:00, 13:00 — 15:00"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"sluzby"} layout={"l1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" style={{"maxWidth":299}} content={"Komplexní vyšetření zraku"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Objednat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" style={{"maxWidth":299}} content={"Předpis pro dioptrické brýle"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Objednat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" style={{"maxWidth":299}} content={"Odstranění šedého zákalu"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Objednat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"informace-2"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex el--3" style={{"maxWidth":1350}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--26" content={"✅&nbsp; &nbsp;Pojištěnci všech pojišťoven"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--26" content={"✅&nbsp; &nbsp;Moderní vybavení a technika"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--26" content={"✅&nbsp; &nbsp;Přijímáme nové pacienty"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1so983 pb--80 pt--80" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/136/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Objednat k vyšetření</span>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Objednat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":476}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">MUDr. Róbert Kachlička</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Holandská 854/1, Brno<br>+420 797 811 2X0<br>info@vase-stranky.com<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}